<template lang="pug">
CanvasModal.trim-overlay(
  :isShow="isShow"
  @closeOverlay="closeTrim"
)
  .trim-header__container
    span.trim-header
      i.icon-trim
      p.header-title Trim Video

    .header--right
      BaseButton.btn-apply(
        v-if="!isPermanentTrim"
        is-primary
        :is-canvas="true"
        :disabled="isLoading"
        @click="confirmTrim"
      ) Done

      BaseButton.btn-trim-video-permanently(
        v-if="isPermanentTrim"
        is-alert
        :is-canvas="true"
        :disabled="isLoading"
        @click="showConfirmPermanentTrimWarningCheck"
      ) Trim permanently

  .trim-overlay__container
    .loader-container(:class="{'container--hidden': !isLoading}")
      .loader
        Lottie.loader__animation(:options="canvasLoaderOptions")
        p.loader__text
          span(v-if="videoLoadingPercentage >= 100") Loading Footage
          span(v-else) Initializing ({{videoLoadingPercentage}}%)
      .video-poster-container(ref="videoPoster")
        div.video-poster(v-show="hasCapturedVideoFrame" ref="videoPosterContainer")
        img(v-show="!hasCapturedVideoFrame" :src="videoPosterThumbnail")

    video(
      ref="trimVideo"
      :class="{ 'is-hidden': isLoading }"
      :poster="videoPoster"
      :crossorigin="!isPixabayVideo"
    )
      source(:src="videoSourceUrl" type="video/mp4")

  .trim-video__footer(v-if="showVideoFooter")
    button.btn-play-trim.btn-play(
      :class="{ 'is-playing': isPlaying, 'is-disabled': isLoading }"
      type="button"
      @click="playTrim"
    )
      i.icon.icon-play
      span.white-background-overlay(v-if="isDarkMode")
    .trim-overlay__video(v-if="isReadyToTrim")
      .trim-overlay__active-bar(
        :style="activeBarStyle"
      )
      .trim-overlay__bar
      VueDragResize.trim-overlay__resizer(
        v-if="isAlwaysActive"
        v-click-outside="onClickOutside"
        :class="{ 'is-dark': isDarkMode }"
        :isActive="true"
        :w="elementDurationWidth"
        :minw="minimumDurationWidth"
        :h="36"
        :x="startPos"
        :y="0"
        axis="x"
        :isResizable="true"
        @resizing="updateDuration"
        @dragging="updateDuration"
        @resizestop="debounceUpdateUrl"
        @dragstop="debounceUpdateUrl"
        :sticks="['ml', 'mr']"
        :parentLimitation="true"
      )
        .bar(
          :style="barPosition"
          :class="{ 'is-trimming': isTrimming }"
        )

    .trim-overlay__video--is-loading(v-else)
      BasePreloader(:type="12" :height="50")

    .trim-overlay__duration(v-if="isReadyToTrim")
      .trim-overlay__timer
        label(for="starttime") Start
        input#starttime.timer(
          type="type"
          name="starttime"
          v-model="humanizeStartTime"
          :readonly="readOnlyInput"
          v-mask="'##:##:##'"
          @change="doUpdateStartEndTime"
          @keyup.enter="doUpdateStartEndTime"
        )
      .trim-overlay__timer
        label(for="endtime") End
        input#endtime.timer(
          autocomplete="off"
          type="text"
          name="endtime"
          v-model="humanizeEndTime"
          :readonly="readOnlyInput"
          v-mask="'##:##:##'"
          @change="doUpdateStartEndTime"
          @keyup.enter="doUpdateStartEndTime"
        )

  GlobalEvents(
    v-if="showTrim && !isLoading"
    :filter="(event, handler, eventName) => allowSpacebarEvent(event)"
    @keydown.prevent.space="playTrim"
  )

  BaseDialog.permanent-trim-warning(
    v-if="showConfirmPermanentTrimWarning"
    is-show=true
    is-alert=true
    @confirm="confirmTrimPermanently()"
    @closeOverlay="showConfirmPermanentTrimWarning = false"
  )
    p Warning, by trimming this permanently it will also applied to other project using this video.

    label.confirm-permanent-trimming-checkbox
      input(type="checkbox" v-model="showConfirmPermanentTrimWarningCheckbox")
      span Don't show this alert again.

</template>

<script>
import {
  mapGetters,
  mapMutations,
  mapState,
  mapActions,
} from 'vuex';
import VueDragResize from 'vue-drag-resize';
import vClickOutside from 'v-click-outside';
import cloneDeep from 'lodash.clonedeep';
import Cookies from 'js-cookie';
import { debounce } from 'debounce';

import Lottie from '@/components/ProjectCanvas/AssetSidebarCanvas/LottieShared.vue';
import CanvasLoader from '@/assets/data/canvas-loader.json';
import {
  minDuration,
  secToHour,
  hourToSec,
  _time,
} from '@/assets/scripts/variables';
import { removeDuplicates, isIOS } from '@/assets/scripts/utilities'; // eslint-disable-line

import Api from '@/services/api/Api';

import helperMixin from '@/components/mixins/helper-mixins';
import darkModeMixin from '@/components/mixins/dark-mode-mixins';

const hidePermanentTrimDialogCookie = 'offeo_hide_permanent_trim_dialog';

export default {
  name: 'TrimOverlay',
  mixins: [helperMixin, darkModeMixin],
  directives: {
    clickOutside: vClickOutside.directive,
  },
  data() {
    return {
      isReadyToTrim: false,
      isPlaying: false,
      readOnlyInput: false,
      isShow: true,
      isTrimming: false,
      isLoading: true,
      isAlwaysActive: true,
      showConfirmPermanentTrimWarning: false,
      hasCapturedVideoFrame: false,
      fileType: '',
      trimVideoUrl: '',
      videoInterval: '',
      userFileId: '',
      capturedVideoFrameImage: '',
      showConfirmPermanentTrimWarningCheckbox: '',
      containerWidth: 1040,
      currentVideoTimePosition: 0,
      currentTime: 0,
      humanizeStartTime: 0,
      humanizeEndTime: 0,
      minimumPermanentTrimDuration: 1.5,
      selectedVideoDuration: 0,
      canvasLoaderOptions: {
        renderer: 'svg',
        loop: true,
        autoplay: true,
        animationData: CanvasLoader,
      },
      fullDuration: 0,
      showVideoFooter: false,
      videoLoadingPercentage: 0,
    };
  },
  components: {
    VueDragResize,
    Lottie,
  },
  computed: {
    ...mapState(['showTrim', 'showPermanentTrimElement']),
    ...mapState('canvasElements', [
      'cachedVideos',
      'loadedVideoElements',
    ]),
    ...mapGetters('canvasElements', [
      'getTrimmedElement',
      'getAllScenesDuration',
      'getCurrentSceneDuration',
    ]),
    ...mapGetters('assetsSidebar', ['getActiveFileCategory']),
    trimElement() {
      let trimElement = null;

      trimElement = this.$refs.trimVideo;

      return trimElement;
    },
    duration() {
      const duration = this.endTime - this.startTime;
      return this.checkDurationWithActualVideo(duration);
    },
    startTime: {
      get() {
        const time = this.getTrimmedElement.data.time_start ? this.getTrimmedElement.data.time_start : 0;
        return parseFloat(parseFloat(time).toFixed(1));
      },
      set(value) {
        const newElement = JSON.parse(JSON.stringify(this.getTrimmedElement));
        newElement.data.time_start = parseFloat(parseFloat(value).toFixed(1));
        this.updateTrimmedElement(newElement);
      },
    },
    endTime: {
      get() {
        const time = this.getTrimmedElement.data.time_end
          ? this.getTrimmedElement.data.time_end
          : this.getCurrentSceneDuration;
        const endTime = parseFloat(parseFloat(time).toFixed(1));
        return this.checkDurationWithActualVideo(endTime);
      },
      set(value) {
        const newElement = JSON.parse(JSON.stringify(this.getTrimmedElement));
        newElement.data.time_end = parseFloat(parseFloat(value).toFixed(1));
        this.updateTrimmedElement(newElement);
      },
    },
    // fullDuration: {
    //   get() {
    //     let fullDuration;

    //     if (this.getTrimmedElement.max_duration === 'full_video_duration') {
    //       // wait to fully load the video to get the full video duration
    //       fullDuration = this.getCurrentSceneDuration;
    //     } else {
    //       fullDuration = this.getTrimmedElement.max_duration || this.getCurrentSceneDuration;
    //     }

    //     return fullDuration;
    //   },
    //   set(value) {
    //     const newElement = JSON.parse(JSON.stringify(this.getTrimmedElement));
    //     newElement.max_duration = parseFloat(parseFloat(value).toFixed(1));
    //     newElement.data.time_end = parseFloat(parseFloat(value).toFixed(1));
    //     this.updateTrimmedElement(newElement);
    //   },
    // },
    elementDurationWidth() {
      let elementDuration = this.duration;
      if (elementDuration === 0) elementDuration = 1;

      const width = this.percentage(elementDuration) * this.containerWidth;

      return width;
    },
    minimumDurationWidth() {
      const minimumDurationTime = this.isPermanentTrim ? this.minimumPermanentTrimDuration : minDuration.fullAnimation;

      const percentage = this.percentage(minimumDurationTime);
      // console.log('minimumDurationTime: ', percentage, this.containerWidth, minimumDurationTime, secToPixel(minimumDurationTime), pixelToSec(this.containerWidth));

      return this.containerWidth * percentage;
    },
    startPos() {
      let pos = this.percentage(this.startTime) * this.containerWidth;
      if (isNaN(pos)) pos = 0; // eslint-disable-line
      return pos;
    },
    barPosition() {
      const styleObject = {};

      styleObject.transform = `translateX(${this.currentVideoTimePosition}px)`;

      return styleObject;
    },
    isPermanentTrim() {
      return this.showPermanentTrimElement;
    },
    videoPoster() {
      return this.capturedVideoFrameImage || this.videoPosterThumbnail;
    },
    videoPosterThumbnail() {
      return this.getTrimmedElement.data.thumb;
    },
    activeBarStyle() {
      const styleObj = {};

      styleObj.width = `${this.elementDurationWidth}px`;
      styleObj.left = `${this.startPos}px`;

      return styleObj;
    },
    isPixabayVideo() {
      return this.trimVideoUrl.includes('pixabay.com/video');
    },
    videoSourceUrl() {
      const url = this.cachedVideos[this.trimVideoUrl]?.url || this.trimVideoUrl;
      return `${url}#t=${this.startTime},${this.endTime}?session=${_time()}`;
    },
  },
  methods: {
    ...mapMutations([
      'setShowTrim',
      'setShowTrimOverlay',
      'setShowPermanentTrimElement',
    ]),
    ...mapMutations('canvasElements', [
      'confirmTrimmedElement',
      'updateTrimmedElement',
      'setLoadedVideoElements',
      'updateCachedVideos',
    ]),
    ...mapActions('canvasHistory', ['catchHistory']),
    ...mapMutations('assetsSidebar', ['updateSidebarElement']),
    allowSpacebarEvent(event) {
      return (
        event
        && event.target.tagName !== 'INPUT'
        && event.target.tagName !== 'TEXTAREA'
        && event.target.getAttribute('contenteditable') !== 'true'
      );
    },
    percentage(value) {
      let percentage = value / this.fullDuration;
      if (percentage > 1) percentage = 1;
      return percentage;
    },
    playTrim() {
      this.isPlaying = !this.isPlaying;

      if (!this.trimElement) return;
      this.trimElement.currentTime = this.startTime;

      if (this.isPlaying) {
        this.trimElement.play();
        this.playVideoBar(this.trimElement);
      } else {
        this.trimElement.pause();
      }
    },
    pauseTrim() {
      if (this.isPlaying) {
        this.playTrim(); // pause if playing;
      }
    },
    closeTrim() {
      this.setShowTrimOverlay(false);
      this.setShowPermanentTrimElement(false);

      if (this.trimElement) {
        this.trimElement.pause();
      }
    },
    updateDuration(el) {
      this.isTrimming = true;
      this.pauseTrim();

      const { width, left } = el;
      const timeStart = parseFloat(((left / this.containerWidth) * this.fullDuration).toFixed(1));
      const duration = parseFloat(((width / this.containerWidth) * this.fullDuration).toFixed(1));

      this.humanizeStartTime = secToHour(timeStart);
      this.humanizeEndTime = secToHour(timeStart + duration);

      // console.log('humanizeEndTime', timeStart, duration, this.humanizeEndTime);

      const newElement = cloneDeep(this.getTrimmedElement);

      if (newElement.timeline_settings) {
        const timelineSettings = newElement.timeline_settings;
        const { animateOutDuration, animationDuration } = timelineSettings;
        timelineSettings.animationDuration = duration;
        timelineSettings.animateOutStart = Math.floor((timeStart + animationDuration - animateOutDuration) * 10) / 10;
      }

      // console.table({
      //   timeStart,
      //   duration
      // })

      newElement.data.time_start = timeStart;
      newElement.data.time_end = timeStart + duration;
      // this.updateTrimmedElement(newElement);

      this.$nextTick(() => {
        this.doUpdateStartEndTime();
      });
    },
    confirmTrim() {
      this.catchHistory('element');

      this.confirmTrimmedElement();
      this.closeTrim();
      /* eslint-disable */
      setTimeout(() => {
        this.$root.$emit('force-reload-timeline');
      }),
        300;
      /* eslint-enable */
    },
    showConfirmPermanentTrimWarningCheck() {
      if (this.startTime + this.endTime === this.fullDuration) {
        this.alertError('Permanent trim failed', 'No changes made, permanent trim cancelled.', 5000, 'TopCenterNotif');
        return;
      }
      if (Cookies.get(hidePermanentTrimDialogCookie) === 'true') {
        this.confirmTrimPermanently();
        return;
      }
      this.showConfirmPermanentTrimWarning = true;
    },
    confirmTrimPermanently() {
      if (this.showConfirmPermanentTrimWarningCheckbox) {
        Cookies.set(hidePermanentTrimDialogCookie, true, {
          expires: 60 * 24 * 365,
        }); // 1 year
      }

      const params = {
        user_file: this.userFileId,
        trim_points: {
          start: this.startTime,
          end: this.endTime,
        },
      };
      Api.trimUserVideoFiles(params)
        .then((response) => {
          // console.log("trimUserVideoFiles:response", response)

          const { results } = response.data;

          this.alertSuccess('', 'Video trimming started. You will be notified once done.', 5000, 'TopCenterNotif');

          this.updateSidebarElement({
            id: results.id,
            group: 'my-files',
            subgroup: 'videos',
            slug: this.getActiveFileCategory,
            data: results,
          });
        })
        .catch((error) => {
          console.log('trimUserVideoFiles:error', error);
          this.alertError('Video trimming failed.', 'Please try again later', 5000, 'TopCenterNotif');
        });

      // console.log(this.startTime, this.endTime);
      this.closeTrim();
      /* eslint-disable */
      setTimeout(() => {
        this.$root.$emit('force-reload-timeline');
      }),
        300;
      /* eslint-enable */
    },
    debounceUpdateUrl() {
      // for a fluent loading state
      this.isLoading = true;
      this.doDebounceUpdateUrl();
    },
    // eslint-disable-next-line func-names
    doDebounceUpdateUrl: debounce(function () {
      this.updateUrl();
    }, 500),
    updateUrl() {
      // let url = this.trimVideoUrl;

      // eslint-disable-next-line
      // url = url.split('#t=')[0];

      // if (this.endTime) {
      //   url = `${url}${this.videoTimingUrl}`;
      // }

      // this.trimVideoUrl = url;

      // const newUrlFile = new URL(this.trimVideoUrl);
      // newUrlFile.searchParams.set('session', _time());
      // console.log('newUrlFile.href', newUrlFile);
      // this.trimVideoUrl = newUrlFile.href;

      const video = this.$refs.trimVideo;

      video.load();

      let percent = 0;
      video.addEventListener('progress', (event) => {
        const vid = event.target;
        if (vid.buffered.length > 0 && vid.buffered.end && vid.duration) {
          percent = vid.buffered.end(0) / vid.duration;
        } else if (vid.bytesTotal != undefined && vid.bytesTotal > 0 && vid.bufferedBytes != undefined) {
          percent = vid.bufferedBytes / vid.bytesTotal;
        }
        if (percent !== null) {
          this.videoLoadingPercentage = parseFloat(parseFloat(100 * Math.min(1, Math.max(0, percent))).toFixed(0));
        }
      }, false);

      video.onpause = () => {
        // console.log('paused');
        clearInterval(this.videoInterval);
        this.isPlaying = false;
      };
      video.onloadedmetadata = () => {
        // console.log('onloadedmetadata');
        // if (this.getTrimmedElement.max_duration === 'full_video_duration' && this.fullDuration !== video.duration) {
        //   this.fullDuration = video.duration;
        //   this.humanizeEndTime = secToHour(video.duration);
        //   this.pauseTrim();
        // }
        this.fullDuration = video.duration;
        this.pauseTrim();
        this.playTrim();
      };
      video.oncanplay = (event) => {
        // console.log('canplay');
        if (event.target.readyState >= 3) {
          this.isLoading = false;
          this.renderVideoPoster();
          setTimeout(() => {
            this.isTrimming = false;
          }, 750);
        }
      };
    },
    playVideoBar(video) {
      // this is for the bar animation
      this.videoInterval = setInterval(() => {
        this.currentVideoTimePosition = ((video.currentTime - this.startTime) / this.duration) * this.elementDurationWidth;
        if (video.currentTime >= this.endTime) {
          // console.log('pausedd')
          video.pause();
          this.isPlaying = false;
          clearInterval(this.videoInterval);
          // eslint-disable-next-line
          video.currentTime = this.startTime;
        }
      }, 50);
    },
    onClickOutside() {
      this.isAlwaysActive = false;

      this.$nextTick(() => {
        this.isAlwaysActive = true;
      });
    },
    doUpdateStartEndTime() {
      const startTime = hourToSec(this.humanizeStartTime);
      const endTime = hourToSec(this.humanizeEndTime);
      this.startTime = startTime;
      this.endTime = endTime;
      this.pauseTrim();

      this.$nextTick(() => {
        this.debounceUpdateUrl();
      });
    },
    checkDurationWithActualVideo(duration) {
      // eslint-disable-next-line
      // this method is created to fix issue where actual video duration is 3s which is smaller than 5s the one that is initially set,
      // but trimmer is forced to be 5s, end up video playing will stuck at 3s.
      if (!this.selectedVideoDuration) return duration;

      // eslint-disable-next-line
      const parsedSelectedVideoDuration = parseFloat(parseFloat(this.selectedVideoDuration).toFixed(1));
      return this.selectedVideoDuration < duration ? parsedSelectedVideoDuration : duration;
    },
    renderVideoPoster() {
      if (this.isPixabayVideo || this.$refs.videoPosterContainer === undefined) {
        return;
      }

      this.$refs.videoPosterContainer.innerHTML = '';

      const video = this.trimElement;
      const canvas = document.createElement('canvas');
      const width = video.videoWidth / 2; // divide by 2 to smaller the size of file, but still get the ratio
      const height = video.videoHeight / 2;
      canvas.width = width;
      canvas.height = height;

      canvas.getContext('2d').drawImage(video, 0, 0, width, height);
      const posterImage = new Image();

      try {
        posterImage.crossOrigin = 'anonymous';
        posterImage.src = canvas.toDataURL('image/jpg');
        this.$refs.videoPosterContainer.appendChild(posterImage);
        this.capturedVideoFrameImage = canvas.toDataURL('image/jpg');
        this.hasCapturedVideoFrame = true;
      } catch (error) {
        console.log(error);
        this.capturedVideoFrameImage = '';
        this.hasCapturedVideoFrame = false;
      }
    },
    videoUrlToBlob(url) {
      const req = new XMLHttpRequest();
      req.open('GET', url, true);
      req.responseType = 'blob';

      req.onload = (event) => {
        const { target } = event;
        // console.log('url!!!!', target);
        // Onload is triggered even on 404
        // so we need to check the status code
        if (target.status === 200) {
          const videoBlob = target.response;
          const videoBlobUrl = URL.createObjectURL(videoBlob); // IE10+
          const loadedVideoElements = JSON.parse(JSON.stringify(this.loadedVideoElements));
          loadedVideoElements.push(url);
          this.setLoadedVideoElements(removeDuplicates(loadedVideoElements));
          this.updateCachedVideos({
            videoUrl: url,
            data: {
              url: videoBlobUrl,
              isVideoLoaded: true,
            },
          });
        }
      };

      req.onerror = (error) => {
        console.error(error);
      };

      return req.send();
    },
  },
  watch: {
    fullDuration(val) {
      if (val > 0) {
        this.showVideoFooter = true;
      }
    },
  },
  beforeMount() {
    // console.log('beforemount: this.getTrimmedElement', this.getTrimmedElement);
    this.videoBlob = '';
    this.videoLoadingPercentage = 0;
    if (this.getTrimmedElement.type === 'videos') {
      this.fileType = 'video';
      const { url } = this.getTrimmedElement.data;
      // eslint-disable-next-line
      // url = url.split('#t=')[0];

      // this.trimVideoUrl = `${url}${this.videoTimingUrl}`;
      this.trimVideoUrl = url;
      // const newUrlFile = new URL(this.trimVideoUrl);
      // newUrlFile.searchParams.set('session', _time());
      // this.trimVideoUrl = newUrlFile.href;

      this.readOnlyInput = false;
      this.containerWidth = 481;

      // for trimming permanently purposes
      if (this.getTrimmedElement.type === 'videos' && this.getTrimmedElement.file_id && this.isPermanentTrim) {
        this.userFileId = this.getTrimmedElement.file_id;
      }
    }
  },
  mounted() {
    this.videoUrlToBlob(this.trimVideoUrl);
    this.trimElement.onloadedmetadata = () => {
      // console.log('ready to catch real video duration');
      // console.log(this.trimElement, this.trimElement.duration);
      this.selectedVideoDuration = this.trimElement.duration;

      this.updateUrl();
      this.humanizeStartTime = secToHour(this.startTime);
      this.humanizeEndTime = secToHour(this.endTime);

      this.isReadyToTrim = true;
    };
  },
  beforeDestroy() {
    this.$refs.trimVideo.removeEventListener('progress', () => {});
  },
};
</script>

<style lang="scss">
.trim-overlay {
  .canvas-modal__content {
    width: 900px;
    background: var(--white-darkgrey700);
  }

  .canvas-modal__closebtn {
    color: var(--darkgrey-white);
  }

  .content {
    display: flex;
    justify-content: center;
    align-items: center;
    align-content: center;
    flex-wrap: wrap;
    padding: 40px 80px 30px;
  }

  .trim-header__container {
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;

    .trim-header {
      display: flex;
      align-items: center;
      color: var(--darkgrey-white);

      .header-title {
        font-size: 1.125rem;
        font-weight: 600;
        margin: 0;
        margin-left: 10px;
      }

      .icon-trim {
        font-size: 1.25rem;
      }
    }

    .btn-apply {
      background: $blue2;
      width: 95px;
      height: 36px;

      &:hover {
        background: $light;
        color: $blue2;
      }
    }

    .btn-trim-video-permanently {
      height: 36px;
    }
  }
}

.trim-overlay__container {
  width: 100%;
  margin: 15px 0 10px;
  max-height: 450px;

  video {
    width: 100%;
    height: auto;
    height: 450px;

    &.is-hidden {
      opacity: 0;
      width: 0;
    }
  }

  .loader-container {
    width: 100%;
    height: 450px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 4px;
    position: relative;

    &.container--hidden {
      opacity: 0;
      visibility: hidden;
      width: 0;
      height: 0;
    }

    .video-poster-container {
      position: absolute;
      z-index: 2;
      height: 100%;
      width: 100%;
      opacity: 0.4;

      .video-poster {
        height: 100%;
        width: 100%;
      }

      img {
        height: 100%;
        width: 100%;
        object-fit: contain;
      }
    }

    .loader {
      position: relative;
      z-index: 5;
      display: flex;
      align-items: center;
      max-width: 250px;
    }

    .loader__animation {
      width: 50px !important;
    }

    .loader__text {
      font-size: 0.9375rem;
      color: var(--darkgrey-white);
      text-shadow: 2px 2px 10px rgba($black, 0.75);
    }
  }
}

.trim-video__footer {
  display: flex;
  flex-wrap: nowrap;
  width: 900px;

  .trim-overlay__duration {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .trim-overlay__timer {
    margin-right: 15px;

    &:last-of-type {
      margin-right: 0;
    }

    label {
      display: block;
      font-size: 0.6875rem;
      margin-bottom: 3px;
      color: var(--darkgrey-white);
    }

    input {
      display: block;
      width: 75px;
      height: 32px;
      padding: 8px 10px;
      border-radius: $componentBorderRadius;
      border: 1px solid var(--lightgrey400-white);
      color: var(--darkgrey-white);
      font-family: $baseFont;
      font-weight: 500;
      font-size: 0.75rem;
      outline: 0;
      background: transparent;

      &:disabled {
        color: $textGrey;
      }

      &:focus:not(:read-only) {
        border-color: $inputFocus;
      }

      &:read-only {
        cursor: auto;
      }
    }
  }

  .trim-overlay__video--is-loading,
  .trim-overlay__video {
    width: 65%;
    margin-top: 20px;
    margin-right: auto;
  }

  .trim-overlay__video--is-loading {
    max-height: 40px;
    margin-left: auto;
  }

  .trim-overlay__video {
    position: relative;

    .trim-overlay__resizer {
      height: 36px;
      top: 50% !important;
      transform: translateY(-50%);
      background: rgba($sectionBlue, 0.5);

      &.is-dark {
        background: rgba($lightGrey400, 0.15);
      }

      &::before {
        border: 0 !important;
        outline: 0 !important;
      }

      .content-container {
        height: 36px !important;
      }

      .vdr-stick {
        width: 14px !important;
        height: 38px !important;
        background: var(--blue-darkgrey5);
        border-radius: 4px;
        position: absolute;
        top: -2px;
        margin: 0 -2px !important;
        border: 0;

        &::before {
          content: '';
          position: absolute;
          z-index: 4;
          top: 2px;
          font-size: 0.375em;
          line-height: 1;
          margin-top: 0;
          border: 0;
          background: $light;
          box-shadow: none;
          justify-content: center;
          align-items: center;
          cursor: ew-resize;
          width: 2px;
          border: 2px solid var(--blue-darkgrey5);
          border-radius: 4px;
          display: flex;
          height: 14px;
          border-top: 10px solid var(--blue-darkgrey5);
          border-bottom: 10px solid var(--blue-darkgrey5);
        }

        &::before {
          left: 4px;
        }

        &::after {
          right: 4px;
        }
      }

      .bar {
        width: 2px;
        height: 100%;
        background: var(--darkgrey-white);
        top: 0;
        position: absolute;
        left: 0;
        transition: transform 0.1s linear;

        &.is-trimming {
          opacity: 0;
        }
      }
    }

    .trim-overlay__bar {
      height: 2px;
      background: var(--lightgrey400-darkgrey600);
      position: relative;
      width: 100%;
      top: 50%;
      transform: translateY(-50%);
    }

    .trim-overlay__active-bar {
      height: 2px;
      background: var(--darkgrey-lightgreen2);
      position: relative;
      top: 50%;
      transform: translateY(-50%);
    }

    .timer-section {
      display: flex;
      flex-direction: column;
      width: 48%;
      margin-right: 2%;

      label {
        font-weight: bold;
      }
    }
  }

  .btn-play-trim {
    position: relative;
    background: transparent;
    font-size: 2.5rem;
    line-height: 0.6;
    margin-right: 15px;
    margin-top: 20px;
    color: $blue;
    padding: 0;

    &:hover {
      color: $btnPrimaryHover;
    }

    &:not(.is-disabled) {
      &.is-playing {
        .icon::before {
          content: $icon-pause;
        }
      }
    }

    &.is-disabled {
      pointer-events: none;
      opacity: 0.5;
    }

    .icon {
      z-index: 3;
      position: relative;
    }

    // to add white background in play/pause button with smaller size than it's icon
    .white-background-overlay {
      position: absolute;
      z-index: 1;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 17px;
      height: 19px;
      background: $light;
      border-radius: 50%;
    }
  }
}

.btn-trim-video-permanently {
  margin-left: 10px;
}
</style>
